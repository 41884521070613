import styled from '@emotion/styled'

export const NavigationSection = styled.section`
    background-color: rgba(255,255,255,0.8);
    padding: 0.5rem 1.25rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    z-index: 10;
    width: 16rem;
    margin: auto;
    border-radius: 0 0 1rem  1rem;
    box-shadow: 0  1rem  1rem rgba(0,0,0,0.1);
    top: 0;
`
export const NavigationItem = styled.div`
    padding: 0.3rem;
    font-size: 1rem;

    a:link {
        text-decoration: none;
        color: black;
    }

    a:visited {
        color: black;
    }
`
