import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled'

export const scrollBy = keyframes`
from {
    transform: translateY(2vh);
    opacity: 0;
    visibility: visible;
  }
  10%, 90%{
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-2vh);
    opacity:0;
  }
`

export const flyBy = keyframes`
from {
    transform: scale(1.2,1.2);
    opacity: 0;
  }
  20% , 80%{
    transform: translateX(0) scale(1,1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(.3,.3);
  }
`

export const zoomOut = keyframes`
from {
    transform: scale(1.2,1.2);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(.3,.3);
  }
`

export const NewsItemSection = styled.section`
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    padding-block-end: 2rem;
    padding-block-start: 2rem;
    width: 100%;
`;


export const Description = styled.p`
    font-size: 0.85rem;
`;

export const NewsItem = styled.a <{ show: boolean }> `
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform-origin: center;
    visibility: hidden;
    opacity: 0;
    animation: ${props => (props.show && css`${scrollBy} 7s`)};
    top:0;
    left:0;
    right:0;
    bottom: 0;
    color: white;
  
    &:link {
        text-decoration: none;
    }

    &:visited {
        color: white;
    }
`;

export const NewsList = styled.div`
    position: relative;
    height: 6rem;
    overflow: hidden;
    margin: 0 2rem;
`;

export const Title = styled.div`
    font-size: 1.5rem;
    line-height: 1.5rem;
    min-height: 3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding: 0; 
    margin: 0;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-weight: bold;
    max-width: 100vw;
    
  `;

export const PubLished = styled.span`
    font-size: 0.7rem;
    padding: 0; 
    margin: 0;
    color: rgba(255,255,255,0.5);
    margin-block-start: 0.2rem;
`;

export const PubLishedBy = styled.p`
  font-size: 1rem;
  padding: 0; 
  margin: 0;
  margin-block-end: 0.3rem;
`;

export const NewsTickerHeader = styled.div`
  margin-block-end: 1.5rem;
`;