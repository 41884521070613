import styled from '@emotion/styled'

export const Background = styled.div`
  background-image: url("./graphics/background_0006_small.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index : -1;
`;

export const MainSection = styled.section`
  width: 100vw;
  height: calc(100vh - 56px);
  color: white;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  position: relative;
`;

export const MainContainer = styled.div`
  color: white;
  text-align: center;
  margin: 2rem 2rem 2rem;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  felx-direction: column;
`;
