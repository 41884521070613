import { NavigationItem, NavigationSection } from "./navigation.style";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <NavigationSection>
      <NavigationItem>
        <NavLink to="/">Home</NavLink>
      </NavigationItem>
      <NavigationItem>
        <NavLink to="/news">News</NavLink>
      </NavigationItem>
      {/* <NavigationItem>
        <NavLink to="/electricity">Electricity</NavLink>
      </NavigationItem>
  */}
    </NavigationSection>
  );
};
export default Navigation;
