import { useContext, useEffect, useState } from "react";
import { RSSDataContext } from "../context/rss-data-provder";
import { NewsData } from "../shared/news-data";
import { Size } from "../shared/size";
import Header from "./header";
import {
  Title,
  NewsItemSection,
  NewsItem,
  NewsList,
  PubLished,
  PubLishedBy,
  NewsTickerHeader,
} from "./news.style";

const News = () => {
  const newsData = useContext(RSSDataContext);
  const [posts, setPosts] = useState<NewsData[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    newsData.data && setPosts(newsData.data);
  }, [newsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (posts) {
        const nextIndex =
          currentIndex !== posts.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(nextIndex);
      }
    }, 7000);
    return () => clearInterval(interval);
  }, [posts, currentIndex]);

  return (
    <NewsItemSection>
      <NewsTickerHeader>
        <Header title="Sidste nyt:" size={Size.small} color="#ced4da" />
      </NewsTickerHeader>
      <NewsList>
        {posts.map((post, index) => {
          return (
            <NewsItem
              href={post.link}
              target="_blank"
              key={"news-item-" + index}
              show={currentIndex === index ? true : false}
            >
              <PubLishedBy>{post.source}</PubLishedBy>
              <Title>{post.title}</Title>
              <PubLished>{post.published}</PubLished>
            </NewsItem>
          );
        })}
      </NewsList>
    </NewsItemSection>
  );
};

export default News;
