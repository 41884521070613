import React, { createContext, useState, useEffect, FC } from "react";
import { Electricity, ElectricityData } from "../shared/electricity-data";

interface IElectricityServiceContext {
  data?: Electricity;
  setForceRefresh: () => void;
}

interface IElectricityServiceProvider {
  children: React.ReactNode;
}

const ElectricityServiceContext = createContext<IElectricityServiceContext>({
  data: undefined,
  setForceRefresh: () => {},
});

const ElectricityServiceProvider: React.FC<IElectricityServiceProvider> = ({
  children,
}: IElectricityServiceProvider) => {
  const [posts, setPosts] = useState<Electricity>();

  const setForceRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const SERVICE_URL =
      "https://electricity-prices-service.vercel.app/electricity";

    fetch(SERVICE_URL)
      .then((response) => response.json())
      .then((electricityData) => {
        setPosts(electricityData.electricPrices as Electricity);
      })
      .catch((error) => console.log("An error occured"));
  };

  return (
    // the Provider gives access to the context to its children
    <ElectricityServiceContext.Provider
      value={{ data: posts, setForceRefresh: setForceRefresh }}
    >
      {children}
    </ElectricityServiceContext.Provider>
  );
};

export { ElectricityServiceContext, ElectricityServiceProvider };
