import styled from '@emotion/styled'
import { Size } from '../shared/size';

export const HeaderSection = styled.section`
   margin: 0;
   padding-block-start: 2rem;
  `;

const handleSize = (size: Size) => {
  switch (size) {
    case Size.large:
      return "5rem";
    case Size.medium:
      return "3.5rem"
    case Size.small:
      return "1.75em";
  }
};

export const Headline = styled.h1<{ size: Size, color?: string }>`
  color:  ${(props) => (props.color) ? props.color : "#21282f"};
  text-align: center;
  font-size: ${(props) => handleSize(props.size)};
  font-weight: 700;
  margin: 0;
`;

