import { useContext, useEffect, useState } from "react";
import Header from "../../components/header";

import { RSSDataContext } from "../../context/rss-data-provder";
import { NewsData } from "../../shared/news-data";
import { Size } from "../../shared/size";
import {
  NewsItemHeader,
  NewsSection,
  NewsItem,
  PublishBy,
  PublishDate,
  Title,
  ReadMore,
  Description,
  Link,
  Refresh,
} from "./news.style";

const News = ({ pageProps }: any) => {
  const { data, setForceRefresh } = useContext(RSSDataContext);
  const [posts, setPosts] = useState<NewsData[]>([]);
  const [shouldSpin, setShouldSpin] = useState(false);

  useEffect(() => {
    if (data) {
      setPosts(data);
      setShouldSpin(false);
    }
  }, [data]);

  const gotoUrl = (url: string | undefined) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const refresh = () => {
    setShouldSpin(true);
    setForceRefresh();
  };

  return (
    <>
      <NewsSection>
        <Header title="Seneste nyheder" size={Size.small} />
        {posts.map((item) => {
          return (
            <NewsItem
              key={item.index}
              onClick={() => {
                gotoUrl(item.link);
              }}
            >
              <Link>
                <NewsItemHeader>
                  <PublishBy>{item.source}</PublishBy>
                  <PublishDate>{item.published}</PublishDate>
                </NewsItemHeader>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
                <ReadMore>Læs mere</ReadMore>
              </Link>
            </NewsItem>
          );
        })}
      </NewsSection>
      <Refresh
        spin={shouldSpin}
        onClick={() => {
          refresh();
        }}
      >
        <img src="./graphics/refresh.png" alt="Refesh button" />
      </Refresh>
    </>
  );
};

export default News;
