import { useEffect, useState } from "react";
import { EaseterEggSection, TheLeader, HiddenBtn } from "./easter-egg.style";

const EasterEgg = () => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(false);
    }, 8900);

    const audioPlayer = new Audio("./audio/dufandtmigv2.mp3");

    if (shouldAnimate) {
      audioPlayer.play();
    }
    return () => {
      audioPlayer.remove();
    };
  }, [shouldAnimate]);

  return (
    <EaseterEggSection>
      <HiddenBtn
        onClick={() => {
          setShouldAnimate(true);
        }}
      />
      <TheLeader animate={shouldAnimate} />
    </EaseterEggSection>
  );
};

export default EasterEgg;
