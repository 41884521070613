import { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import EasterEgg from "./components/easter-egg";
import Main from "./components/main";
import { Background } from "./components/main.style";
import Navigation from "./components/navigation";
import { ElectricityServiceProvider } from "./context/electricity-price-service";
import { RSSDataProvider } from "./context/rss-data-provder";
import Electricity from "./pages/electricity/electricity";
import News from "./pages/news/news";

function App() {
  return (
    <RSSDataProvider>
      <ElectricityServiceProvider>
        <BrowserRouter>
          <Background />
          <EasterEgg />
          <Navigation />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/news" element={<News />} />
            {/* <Route path="/electricity" element={<Electricity />} />*/}
          </Routes>
        </BrowserRouter>
      </ElectricityServiceProvider>
    </RSSDataProvider>
  );
}
export default App;
