import styled from '@emotion/styled'

export const LinksSection = styled.section`
    text-align: center;
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const LinkContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-block-end: 2rem;

    @media (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
    }

`;

export const Link = styled.a`
    padding: 1.15rem;
    border-radius: 2rem;
    background-color: rgba(33, 40, 47, 0.3);
    font-weight: bold;
    color: #21282f;

    &:link {
        text-decoration: none;
        color: white;
    }

    &:visited {
        color: white;
    }
`;

export const NavLinkStyled = styled.span`
    padding: 1.15rem 3rem;
    border-radius: 2rem;
    background-color: rgba(33, 40, 47, 0.3);
    font-weight: bold;
    color: #21282f;


    a:link {
        text-decoration: none;
        color: white;
    }

    a:visited {
        color: white;
    }
`;