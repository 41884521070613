import {
  Link,
  LinkContainer,
  LinksSection,
  NavLinkStyled,
} from "./links.style";
import { NavLink } from "react-router-dom";

const Links = () => {
  return (
    <LinksSection>
      <LinkContainer>
        <Link target="_blank" href="https://abetterrouteplanner.com">
          A Better Route Planner
        </Link>
        <Link target="_blank" href="https://ekstrabladet.dk">
          Ekstra Bladet
        </Link>
        <Link target="_blank" href="https://www.dr.dk/nyheder">
          DR Nyhederne
        </Link>
        <Link target="_blank" href="https://nyheder.tv2.dk">
          TV2 Nyhederne
        </Link>
        <Link target="_blank" href="https://bt.dk">
          B.T.
        </Link>
        <Link target="_blank" href="https://politiken.dk">
          Politiken
        </Link>
        <Link target="_blank" href="https://berlingske.dk">
          Berlingske
        </Link>
        <Link target="_blank" href="https://jp.dk">
          Jyllands Posten
        </Link>
      </LinkContainer>
      <NavLinkStyled>
        <NavLink to="/news">Seneste nyheder</NavLink>
      </NavLinkStyled>
    </LinksSection>
  );
};

export default Links;
