import { FooterSection } from "./footer.style";

const Footer = () => {
  return (
    <FooterSection>
      <p>Morten Gustafsson - 2022</p>
    </FooterSection>
  );
};

export default Footer;
