import React, { createContext, useState, useEffect, FC } from "react";
import { NewsData } from "../shared/news-data";

interface IRSSDataContext {
  data?: NewsData[];
  setForceRefresh: () => void;
}

interface IRSSDataProvider {
  children: React.ReactNode;
}

const RSSDataContext = createContext<IRSSDataContext>({
  data: [],
  setForceRefresh: () => {},
});

const RSSDataProvider: React.FC<IRSSDataProvider> = ({
  children,
}: IRSSDataProvider) => {
  const [posts, setPosts] = useState<NewsData[]>();

  const setForceRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const RSS_SERVICE_URL = "https://rss-fetch-service-x9d8.vercel.app";

    fetch(RSS_SERVICE_URL)
      .then((response) => response.json())
      .then((newsData) => {
        const newsDataList = newsData.posts as NewsData[];
        newsDataList.map((item) => {
          if (item.published) {
            const formatedPublishedDate = new Date(
              item.published
            ).toLocaleDateString("da-DK", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              weekday: "long",
            });
            item.published = formatedPublishedDate;
          }
        });
        setPosts(newsData.posts);
      })
      .catch((error) => console.log("An error occured"));
  };

  return (
    // the Provider gives access to the context to its children
    <RSSDataContext.Provider
      value={{ data: posts, setForceRefresh: setForceRefresh }}
    >
      {children}
    </RSSDataContext.Provider>
  );
};

export { RSSDataContext, RSSDataProvider };
