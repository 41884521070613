import styled from '@emotion/styled'

export const FooterSection = styled.section`
  text-align: center;
  font-size: 0.75rem;
  padding-block-end: 0.5rem;
  padding-block-start: 0.5rem;
  border-top: 1px solid ;
  border-image: linear-gradient(90deg,  rgba(255,255,255,0),  rgba(255,255,255,0.4), rgba(255,255,255,0)) 1;
  width: 100%;
`;
