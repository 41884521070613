import styled from '@emotion/styled'

export const EaseterEggSection = styled.section`
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    z-index:100;
    overflow:hidden;
    pointer-events: none;

`;

export const TheLeader = styled.div<{ animate: boolean }>`
    background-image: url("./graphics/easter-egg.png");
    background-repeat: no-repeat, repeat;
    background-position: bottom;
    background-size: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right:0;
    bottom: 0;
    width: 50vw;
    max-width: 500px;
    transition: transform 1s cubic-bezier(1, 0.1, 0.3, 1);
    transform: ${props => (props.animate ? 'translateX(5vw) rotate(-5deg)' : 'translateX(90vw) rotate(20deg)')};
    `;

export const HiddenBtn = styled.div`
    position: absolute;
    left: 0;
    top: ${Math.random() * (98 - 2) + 2}vh;
    width: 20px;
    height: 20px;
    pointer-events: auto;
`;
