import { Size } from "../shared/size";
import { HeaderSection, Headline } from "./header.style";

type HeaderProps = {
  title: string;
  size: Size;
  color?: string;
};

const Header = ({ title, size, color }: HeaderProps) => {
  return (
    <HeaderSection>
      <Headline color={color} size={size}>
        {title}
      </Headline>
    </HeaderSection>
  );
};

export default Header;
