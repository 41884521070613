import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled'

export const NewsSection = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding-block-start: 3rem;
    padding-block-end: 3rem;
    padding: 3rem 2rem;
`

export const Link = styled.a`
    cursor: pointer;
    display: block;
    border-radius: 1.5rem;
    padding: 1rem 2rem;
    background-color: rgba(255,255,255,0.5);
`;

export const NewsItem = styled.div`
    color: #21282f;
    width: 100%;
    max-width: 55rem;
`

export const PublishBy = styled.p`
    font-size: 1rem;    
    padding: 0;
    margin: 0;
    font-weight: bold;
`

export const PublishDate = styled.p`
    font-size: 0.75rem;    
    margin: 0;
    padding: 0;
    font-style: italic;
`

export const Title = styled.p`
    font-size: 2rem;    
    margin: 1rem 0 ;
    padding: 0;
    font-weight: bold;
    line-height: 2.25rem;
`

export const Description = styled.p`
    font-size: 0.95rem;    
    margin: 0.5rem 0 1rem 0;
    padding: 0;
`

export const NewsItemHeader = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 0.5rem 0 0.5rem 0;
    justify-content: space-between;
`

export const ReadMore = styled.div`
    font-size: 0.75rem;
    text-decoration: underline;
    margin: 0.5rem 0 0.75rem;
`

export const spinner = keyframes`
    to { transform: rotate(360deg); }
`

export const noneSpinner = keyframes`
    to { transform: rotate(0); }
`

export const Refresh = styled.div<{ spin: boolean }>`
    width: 2rem;
    height: 2rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 100;
    opacity: 0.5;
    cursor: pointer;
    animation: ${props => (props.spin && css`${spinner} .6s ease-in infinite`)};

    img {
        width: 100%;
        height:100%; 
    }
`
