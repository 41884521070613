import { Size } from "../shared/size";
import Footer from "./footer";
import Header from "./header";
import Links from "./links";
import { MainSection } from "./main.style";
import News from "./news";

const Main = (data?: any) => {
  return (
    <MainSection>
      <Header title="WebCenter" size={Size.large} />
      <Links />
      <News />
      <Footer />
    </MainSection>
  );
};

export default Main;
